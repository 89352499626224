import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <Auth0Provider
  //   domain="{yourDomain}"
  //   clientId="{yourClientId}"
  //   authorizationParams={{
  //     redirect_uri: window.location.origin
  //   }}
  // >  
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  // </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

