import React, { useEffect } from "react";
import './DiceWidgetPreview.css';


const DiceEventListPreview = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widgets.dice.fm/dice-event-list-widget.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.DiceEventListWidget.create({
        information: "simple",
        eventTitle: "event",
        showImages: true,
        showAudio: true,
        showNewBadge: true,
        hidePostponed: true,
        hideCancelled: true,
        layout: "gallery",
        roundButtons: true,
        theme: "light",
        fontFamily: "inherit",
        partnerId: "bfdbd984",
        apiKey: "KJ5BcUn3hJ7hUwivgTnM93tFGK0asvkq8UPi2Fw8",
        version: 2,
        showPrice: false,
        __p: false,
        __pc: {},
        highlightColour: "#ffffff00",
        promoters: ["Night Harvest", "Art's Cool"],
        numberOfEvents: "8",
        eventListLink: "https://dice.fm/venue/whereelse-r76q?lng=en"
      });

      setTimeout(() => {
        const elementToRemove = document.querySelector('.sc-iAvgwm jIyZid dice_book-now'); // replace with the actual selector of the element you want to remove
    
        if (elementToRemove) {
          elementToRemove.parentNode.removeChild(elementToRemove);
        }
      }, 0);

      const widgetContainer = document.getElementsByClassName("sc-iMnTL.gzGzBm.dice_event-listing-container");

      if (widgetContainer) {
        if (window.innerWidth > 1500) {
          widgetContainer.style.height = '1000px';
        }
      }
    };
  }, []);

  return (
    <>
      <h1>Up Next</h1>
        <div id="dice-event-list-widget">
          {/* Widget will be injected here */}
        </div>
    </>
  );
};


export default DiceEventListPreview;
