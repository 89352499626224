import React from "react";
import '../../index.css';
import { m } from "framer-motion";


const Contact = () => {
  return (
    <>
    <div className="contactContainer">
      <p>where to find us</p>
      <p>21-22 The Centre, Margate CT9 1RL</p>
    </div>
    </>
  )
}

export default Contact; 















