import React, { useEffect, useRef } from "react";
import './signup.css';

const OctoForm = () => {
  const formRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://eocampaign1.com/form/9038272e-77bc-11ed-be23-9790e330dd91.js";
    script.async = true;
    script.setAttribute('data-form', '9038272e-77bc-11ed-be23-9790e330dd91');

    if (formRef.current) {
      formRef.current.appendChild(script);
    }

    return () => {
      const nodes = document.querySelectorAll(`[data-form="9038272e-77bc-11ed-be23-9790e330dd91"]`);
      nodes.forEach(node => node.parentNode.removeChild(node));
    };
  }, []);

  return <div ref={formRef}></div>;
};

export default OctoForm;
