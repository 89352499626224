import React from "react";
import './signup.css';
// import '../../index.css';
import OctoForm from "./Octoform";


const Signup = () => {
  return (
    <>
      <div className="signup-container">
        <p>sign up to our newsletter to get the latest news and updates</p>
        {/* <form className="signup-form">
          <input type="email" placeholder="Email" />
          <button>Sign Up</button>
        </form> */}
        <div className="octo-form">
          <OctoForm />
        </div>
    </div>
    </>
  )
}

export default Signup; 


















