import React, { useEffect, useRef } from "react";
import Navbar from "../../components/Navbar";
import Splash from "./splash";
import Blurb from "./Blurb";
import DiceEventListPreview from "./DiceWidgetPreview";
import KentPopExchangePreview from "./KentPopExchangePreview";
import FadeInTextSection from "../../components/ui/FadeInTextSection";
import HamburgerMenu from "../../components/ui/HamburgerMenu";
import './home.css';
import MerchPreview from "./merchPreview";
import Playlist from "./Playlist";
import Contact from './Contact';
import YouTubeVideo from "../../components/YouTubeVideo";
import Signup from "./Signup";
import MainDisplay from "./mainDisplay";
import Links from "./WebLinks";
import OctoForm from "./Octoform";

function HomeApp() {
  const appRef = useRef(null);

  useEffect(() => {
    const moveGradient = (event) => {
      const winWidth = window.innerWidth;
      const winHeight = window.innerHeight;

      const mouseX = Math.round((event.pageX / winWidth) * 100);
      const mouseY = Math.round((event.pageY / winHeight) * 100);

      if (appRef.current) {
        appRef.current.style.setProperty(
          "--mouse-x",
          mouseX.toString() + "%"
        );
        appRef.current.style.setProperty(
          "--mouse-y",
          mouseY.toString() + "%"
        );
      }
    };

    document.addEventListener("mousemove", moveGradient);

    return function cleanup() {
      document.removeEventListener("mousemove", moveGradient);
    };
  }, [appRef]);

  return (
    <div ref={appRef} className="MasterHomeContainer">
      <div className='navSection'>
        <Navbar />
        <HamburgerMenu />
      </div>
      <div className='videoSection'>
        <Splash />
      </div>
      <div className="content">
        <FadeInTextSection>
          {/* <Blurb /> */}
          <div className="DiceWidgetHomePreview">
            <DiceEventListPreview />
          </div>
          <div> 
            <YouTubeVideo videoId="https://www.youtube.com/embed/FDQMzQGVOVM?autoplay=1&mute=1&loop=1&controls=0&playlist=FDQMzQGVOVM"/>
          </div>
          <div className="mainContainerContainer">
            <MainDisplay /> 
          </div>
          <div className="footerContainer">
            <div className="footerSection1">
              <Signup />
              <Contact />
            </div>
            <div className="footerSection2">
              {/* <Links /> */}
            </div>
          </div>
          {/* <div className="kentPopExchangePreview">
            <KentPopExchangePreview />
          </div> */}
        </FadeInTextSection>
      </div>
    </div>
  );
}

const Home = () => {
  return <HomeApp />;
};

export default Home;
