import React from 'react';
import MerchPreview from "./merchPreview";
import Playlist from "./Playlist";
import Signup from './Signup';
import './mainDisplay.css';

const MainDisplay = () => {
  return (
    <>
    <div className='masterContainer'>
      <div>
        <h1></h1>
      </div>
      <div className='displayContainer1'>
        <MerchPreview />
        {/* <Playlist /> */}
        {/* <Signup /> */}
      </div>
    </div>
    </>
  );
};

export default MainDisplay;